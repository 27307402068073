<script lang="ts" setup>
const props = defineProps<{
  theme: any
}>()

const variablesColor = computed(() => {
  if (
    props.theme &&
    props.theme.background &&
    props.theme.primary &&
    props.theme.secondary
  ) {
    const backgroundColor = useColor(props.theme.background)
    const primary = useColor(props.theme.primary)
    const secondary = useColor(props.theme.secondary)
    return {
      '--primary': primary.getHex(),
      '--secondary': secondary.getHex(),
      '--background': backgroundColor.getHex(),
    }
  }
})

const footerMenuItems = ref([
  {
    name: 'Eva',
    url: '/abonneren/selectie/eva-selectie',
  },
  {
    name: 'Visie',
    url: '/abonneren/selectie/visie-selectie',
  },
  {
    name: 'Beam',
    url: 'https://beam.eo.nl',
  },
  {
    name: 'Nederland Zingt',
    url: 'https://nederlandzingt.eo.nl',
  },
  {
    name: 'Steun de EO',
    url: '/doneren/steun-de-eo',
  },
  {
    name: 'EO Metterdaad',
    url: 'https://www.eo.nl/programmas/eo-metterdaad',
  },
  {
    name: 'Ledenvoordeel',
    url: '/selectie/ledenvoordeelwinkel',
  },
  {
    name: 'Naar de EO website',
    url: 'https://www.eo.nl',
  },
])

const textColor = () => (props.theme.logo === 'beam' ? 'black' : 'white')
</script>
<template>
  <div
    :style="variablesColor"
    :class="[`min-h-10 w-full rounded-tr-full bg-[--secondary]`]"
  ></div>
  <div
    :style="variablesColor"
    class="w-full bg-[--secondary] px-5"
  >
    <div
      :style="variablesColor"
      :class="[
        `mx-auto flex min-h-64 w-full flex-col items-center bg-[--secondary] align-top sm:max-w-6xl sm:flex-row sm:items-start`,
      ]"
    >
      <EoLogo
        brand="eo"
        size="md"
        :color="textColor()"
      />
      <div
        :class="`flex flex-col items-center pt-12 text-${textColor()} sm:ml-28 sm:block sm:w-1/4 sm:pt-0 md:w-1/6`"
      >
        <div class="mb-3 text-lg sm:max-h-4 sm:min-h-4">Ga naar ...</div>
        <div
          class="text-lg font-bold"
          v-for="footerMenuItem in footerMenuItems.slice(0, 4)"
          :key="footerMenuItem.url"
        >
          <NuxtLink
            :to="footerMenuItem.url"
            :class="`text-${textColor()} no-underline`"
            >{{ footerMenuItem.name }}</NuxtLink
          >
        </div>
      </div>
      <div
        class="flex flex-col items-center pb-12 text-lg sm:ml-12 sm:block sm:w-1/4 sm:pb-0 md:w-1/6"
      >
        <div class="font-bold sm:mb-3 sm:max-h-4 sm:min-h-4"></div>
        <div
          class="text-lg font-bold"
          v-for="footerMenuItem in footerMenuItems.slice(4, 8)"
          :key="footerMenuItem.url"
        >
          <NuxtLink
            :to="footerMenuItem.url"
            :class="`font-bold text-${textColor()} no-underline`"
            >{{ footerMenuItem.name }}</NuxtLink
          >
        </div>
      </div>
    </div>
  </div>
  <Footer :theme="theme" />
</template>
