<script lang="ts" setup>
const props = defineProps<{
  theme: any
}>()

const variablesColor = computed(() => {
  if (
    props.theme &&
    props.theme.background &&
    props.theme.primary &&
    props.theme.secondary
  ) {
    const backgroundColor = useColor(props.theme.background)
    const primary = useColor(props.theme.primary)
    const secondary = useColor(props.theme.secondary)
    return {
      '--primary': primary.getHex(),
      '--secondary': secondary.getHex(),
      '--background': backgroundColor.getHex(),
    }
  }
})
const textColor = () => (props.theme.logo === 'beam' ? 'black' : 'white')
</script>
<template>
  <header
    :style="variablesColor"
    :class="[
      `@container sticky top-0 z-40 h-20 w-full bg-[--secondary] px-5 transition-all`,
    ]"
  >
    <div class="mx-auto flex h-full w-full max-w-6xl items-center gap-3">
      <EoLogo
        v-if="theme.logo !== 'eo'"
        :brand="theme.logo"
        size="md"
        :color="textColor()"
      />

      <NuxtLink
        accesskey="1"
        class="@xs:mr-3 flex w-full flex-row justify-center no-underline md:w-1/12 md:justify-start lg:w-1/12"
        to="https://www.eo.nl/"
      >
        <h3 :class="`mx-3 my-auto text-2xl text-${textColor()}`">meer</h3>
        <EoLogo
          class=""
          brand="eo"
          size="md"
          :color="textColor()"
        />
      </NuxtLink>

      <div
        class="mx-auto hidden items-center justify-center gap-4 px-2 sm:gap-6 md:flex md:gap-8 lg:gap-10 lg:text-lg xl:gap-16"
      ></div>
      <NuxtLink
        to="https://npostart.nl"
        target="_blank"
        class="text-right md:w-1/12"
      >
        <EoIcon
          color="npo-300"
          name="npo-start"
          size="4xl"
        />
      </NuxtLink>
    </div>
  </header>
</template>
